<template>
  <div>
    <header-title title="编辑配置"></header-title>
    <rs-row>
     <rs-col :span="12">
        <render-form :list="renderList" :formData.sync="formData"></render-form>
     </rs-col>
    </rs-row>
    <div class="edit-form button-box" style="text-align: right">
        <rs-button size="small"  @click="back">取消</rs-button>
        <rs-button size="small" @click="save" type="primary" :loading="saveLoading">保存</rs-button>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils/utils'
import Api from '@/api/reportServer'
import Api1 from '@/api/api'
import headerTitle from '@/components/header'
export default {
  name: "editReportCenterConfig",
  components:{headerTitle},
  data(){
    return {
      isAdd:true,
      modifyId:'',
      saveLoading:false,
      formData:{
        mallSlect:{},       //选择的项目
        fieldLength: null,
        fieldLengthName: "",
        fieldName: "",
        fieldType: "",
        mallId: null,
        mallName: '选择的项目',
        mantissaMode: null,
        mantissaModeName: "",
        modifyBy: null,
        numberDigit: null     
      },
      renderList: [],
      mantissaModeList:[
        {
          value: 1,
          label: "四舍五入，尾差求和"
        },
        {
          value: 2,
          label: "尾数不进位，尾差求和"
        },
      ],
      mallList:[
      ],
      warnList:[],            //预警类型
      warnDateTypeList:[],    //预警天数的单位:天、月、日等
    }
  },
  created(){
    this.renderPage();
    this.getDetail();
  },
  methods:{
      renderPage() {
        this.renderList = [ 
        {
          span: 24,
          type: "text",
          label: "项目名称",
          key: "mallName"
        },
         {
          span: 24,
          type: "text",
          label: "字段名称",
          key: "fieldName"
        },
        {
          span: 24,
          type: "text",
          label: "字段长度",
          key: "fieldLengthName"
        },
        {
          span: 24,
          type: "select",
          label: "小数保留位数",
          key: "numberDigit",
          optionList: [
            {
              value: 2,
              label: "两位小数"
            },
            {
              value: 4,
              label: "四位小数"
            },
            {
              value: 6,
              label: "六位小数"
            },
            {
              value: 8,
              label: "八位小数"
            },
          ]
        },
        {
          span: 24,
          type: "select",
          label: "尾数计算方式",
          key: "mantissaMode",
          optionList: this.mantissaModeList,
           on: {
              change: (val) => {
                console.log('val',val)
                let tempSele = this.mantissaModeList.find(item=>item.value == val)
                console.log('tempSele',tempSele)
                this.formData.mantissaMode = tempSele.value
                this.formData.mantissaModeName = tempSele.label
              },
            },
        },
      ]
      },
    getDetail(){
      let id =  this.$route.query.id
      console.log('id:',id)
      Api.reportConfigDetail({id}).then(res=>{
        console.log('res:',res)
        this.formData = res.data.data
      })
    },
    back(){
      this.$router.back();
    },
    save(){
      console.log('this.formData',this.formData)
      this.saveLoading=true;
      Api.reportConfigUpdate({...this.formData}).then(res=>{
        this.saveLoading=false;
        if(res.data.code==200){
          this.$message.success('编辑成功!')          
          this.$router.push('/reportCenterConfig');
        }else{
          this.$message.error(res.data.message);
        }
      }).catch(error=>{
        this.saveLoading=false;
      })
      
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .configType{
  .rs-form-item__content{
    margin-left: 40px !important;
  }  
}
</style>