var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header-title", { attrs: { title: "编辑配置" } }),
      _c(
        "rs-row",
        [
          _c(
            "rs-col",
            { attrs: { span: 12 } },
            [
              _c("render-form", {
                attrs: { list: _vm.renderList, formData: _vm.formData },
                on: {
                  "update:formData": function ($event) {
                    _vm.formData = $event
                  },
                  "update:form-data": function ($event) {
                    _vm.formData = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-form button-box",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "rs-button",
            { attrs: { size: "small" }, on: { click: _vm.back } },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                loading: _vm.saveLoading,
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }